import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';

import { FLink } from '../../../common/FLink';
import * as styles from './FooterContent.module.scss';
import { Context } from '../../../../context/Context';
import { localizeField } from '../../../../utils/locale';

const FooterContent = () => {
  const data = useStaticQuery(graphql`
    query FooterContentQuery {
      sanitySingletonCommon {
        #addressHeader {
        #  _type
        #  en
        #  sc
        #  tc
        #}
        #_rawAddressBody(resolveReferences: { maxDepth: 10 })
        #opneingHoursHeader {
        #  _type
        #  en
        #  sc
        #  tc
        #}
        #_rawOpeningHoursBody(resolveReferences: { maxDepth: 10 })
        findUsHeader {
          _type
          en
          sc
          tc
        }
        #_rawFindUsBody(resolveReferences: { maxDepth: 10 })
        _rawFindUsBodyHolding(resolveReferences: { maxDepth: 10 })
      }
    }
  `);

  const content = data.sanitySingletonCommon;
  const { language } = useContext(Context);

  return (
    <div className={styles.footerInfo}>
      {/* Address */}
      {/* <div>
        <h3>{localizeField(content.addressHeader, language.locale)}</h3>

        <BlockContent
          blocks={localizeField(content._rawAddressBody, language.locale)}
        />
      </div> */}
      {/* Opening Hours */}
      {/* <div>
        <h3>{localizeField(content.opneingHoursHeader, language.locale)}</h3>

        <BlockContent
          blocks={localizeField(content._rawOpeningHoursBody, language.locale)}
        />
      </div> */}
      {/* Find Us */}
      <div>
        <h3>{localizeField(content.findUsHeader, language.locale)}</h3>

        {/* <BlockContent
          blocks={localizeField(content._rawFindUsBodyHolding, language.locale)}
        /> */}
        <div>
          <p>
            <a href={`mailto:info@the14thfactory.com?subject=General Inquiry`}>
              info@the14thfactory.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export { FooterContent };
