import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FLink } from '../../common/FLink';
import * as styles from './Navbar.module.scss';
import { Context } from '../../../context/Context';
import { localizeField } from '../../../utils/locale';
import { LocationSwitch } from '../LocationSwitch';
import { useMediaQuery, MediaQuery } from '../../../utils/hooks/mediaQuery';

const Navbar = ({ setMenuOpen, location }) => {
  const data = useStaticQuery(graphql`
    query NavbarQuery {
      sanitySingletonMenus {
        mainMenu {
          slug
          linkText {
            _type
            en
            sc
            tc
          }
        }
      }
    }
  `);

  const menuItems = data.sanitySingletonMenus.mainMenu;
  const { language } = useContext(Context);
  const isTablet = useMediaQuery(MediaQuery.Tablet);

  return (
    <menu className={styles.menu}>
      <div>
        {/* {isTablet && (
          <div className={styles.location}>
            <LocationSwitch location={location} />
          </div>
        )} */}
        {/* <div className={styles.mainMenu}>
          {menuItems.map((item, i) => (
            <div key={`menu-item-${item.slug}-${i}`}>
              <FLink to={`/${item.slug}`} onClick={() => setMenuOpen(false)}>
                {localizeField(item.linkText, language.locale)}
              </FLink>
            </div>
          ))}
        </div> */}
        {/* {!isTablet && (
          <div className={styles.mobileLocation}>
            <LocationSwitch location={location} />
          </div>
        )} */}
      </div>
    </menu>
  );
};

export { Navbar };
