// extracted by mini-css-extract-plugin
export var typeLarge = "Icon-module--type-large--ru0hd";
export var typeMedium = "Icon-module--type-medium---X1yP";
export var typeBody = "Icon-module--type-body--nbaCl";
export var homeIcon = "Icon-module--home-icon--y1nOA";
export var icon = "Icon-module--icon--bJowl";
export var xsmall = "Icon-module--xsmall--awJ--";
export var small = "Icon-module--small--Z9brT";
export var normal = "Icon-module--normal--hKuny";
export var medium = "Icon-module--medium--CwqLZ";
export var large = "Icon-module--large--beg+k";
export var xlarge = "Icon-module--xlarge--I8gSa";