// extracted by mini-css-extract-plugin
export var typeLarge = "Navbar-module--type-large--20e13";
export var typeMedium = "Navbar-module--type-medium--Ms5pZ";
export var typeBody = "Navbar-module--type-body--Rphx+";
export var homeIcon = "Navbar-module--home-icon--rTxkP";
export var menu = "Navbar-module--menu--7gGEv";
export var location = "Navbar-module--location--n0qsR";
export var mobileLocation = "Navbar-module--mobile-location--Jbsq-";
export var mainMenu = "Navbar-module--main-menu--XS9wf";
export var active = "Navbar-module--active--UGb7t";
export var name = "Navbar-module--name--7j5LN";