import React, { useState, useEffect, useLayoutEffect } from 'react';

// import { Link } from "gatsby";
import { FLink } from '../../common/FLink';
import { FLogo } from '../../common/FLogo';
import classNames from 'classnames';
import { Navbar } from '../Navbar';
import { BookTicketsButton } from '../BookTicketsButton';
import { Hamburger } from '../Hamburger';
import { springOne } from '../../../utils/common';

import * as styles from './Header.module.scss';

import { motion } from 'framer-motion';

const Header = ({ location }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const logoHeightDivider = 14;
  const [maxLogoHeight, setMaxLogoHeight] = useState(0);
  const [minLogoHeight, setMinLogoHeight] = useState(0);

  // calculate the heights for the logo
  useLayoutEffect(() => {
    setMaxLogoHeight(window.innerHeight / logoHeightDivider);

    const headerHeight = parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(
        '--header-height'
      ),
      10
    );
    const pagePadding = parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(
        '--page-padding'
      )
    );
    setMinLogoHeight(headerHeight);
    // 45 currently
  }, []);

  const menuVariants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '100%' },
  };

  return (
    <>
      <header className={styles.header}>
        {/* <motion.div
          style={{
            scaleX: scrollYProgress,
            width: "100%",
            backgroundColor: "green",
            height: "10px",
          }}
        /> */}
        <div className={styles.topBar}>
          {/* <motion.div
            className={`${styles.menuTop} ${styles.logoWrapper}`}
            animate={menuOpen ? "open" : "closed"}
            variants={logoVariants}
          > */}

          {/* <div style={{ maxHeight: maxLogoHeight }}> */}
          <FLink
            to="/"
            onClick={() => setMenuOpen(false)}
            className={`${styles.menuTop} ${styles.logoLink}`}>
            <span className="visually-hidden">Fourteenth Factory</span>
            {maxLogoHeight && (
              <FLogo
                className={styles.logo}
                menuOpen={menuOpen}
                minHeight={minLogoHeight}
                maxHeight={maxLogoHeight}
                logoHeightDivider={logoHeightDivider}
                small={
                  location.pathname !== '/' &&
                  location.pathname !== '/sc/' &&
                  location.pathname !== '/tc/'
                }
              />
            )}
          </FLink>

          <div className={`${styles.menuItem} ${styles.menuTop}`}>
            {/* <BookTicketsButton /> */}
            {/* {scrollY.current} */}
          </div>
          {/* <div className={`${styles.menuTop} ${styles.hamburger}`}>
            <Hamburger
              isOpen={menuOpen}
              onClick={() => setMenuOpen(!menuOpen)}
              strokeWidth="4"
              color="#ffffff"
              lineProps={{ strokeLinecap: 'round' }}
              transition={springOne}
              width="26"
              height="26"
            />
          </div> */}
        </div>
        <h2 id="mainmenulabel" className="visually-hidden">
          Main Menus
        </h2>
        {/* <motion.nav
          animate={menuOpen ? 'open' : 'closed'}
          transition={springOne}
          initial={false}
          variants={menuVariants}
          aria-labelledby="mainmenulabel"
          className={styles.nav}> */}
        <div className={styles.localeSelector}>
          <Navbar setMenuOpen={setMenuOpen} location={location} />
        </div>
        {/* </motion.nav> */}
      </header>
    </>
  );
};

export { Header };
