import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "./f14.svg";
import { motion, useViewportScroll } from "framer-motion";

const FLogo = ({
  className,
  menuOpen,
  minHeight,
  maxHeight,
  logoHeightDivider,
  small = false,
}) => {
  const { scrollY } = useViewportScroll();
  const minLogoHeight = minHeight;
  const [maxLogoHeight, setMaxLogoHeight] = useState(maxHeight);

  // no need to update if we don't land on the homepage
  const shouldUpdate = scrollY && !small;
  useEffect(() => {
    // hook into the onChange, store the current value as state.
    if (!small)
      scrollY.onChange((v) => {
        const scrollLogoHeight = window.innerHeight / logoHeightDivider - v;
        setMaxLogoHeight(
          scrollLogoHeight > minLogoHeight ? scrollLogoHeight : minLogoHeight
        );
      });
  }, [shouldUpdate]); //make sure to re-subscriobe when scrollY changes

  const logoVariants = {
    open: {
      width: minLogoHeight,
      height: minLogoHeight,
      x: 0,
    },
    closed: {
      width: small ? minLogoHeight : maxLogoHeight,
      height: small ? minLogoHeight : maxLogoHeight,
      x: small ? 0 : -maxLogoHeight / 5,
    },
  };

  return (
    <motion.div
      animate={menuOpen ? "open" : "closed"}
      variants={logoVariants}
      initial={false}
      // transition={{ duration: 0.2, type: "tween" }}
      transition={{ type: "spring", damping: 50, stiffness: 400 }}
    >
      <Logo className={className} />
    </motion.div>
  );
};

export { FLogo };
