export const colorModes = [
  "home",
  "yellow",
  "green",
  "peach",
  "purple",
  "pink",
  "beige",
  "salmon",
];

export function toPlainText(blocks = []) {
  return (
    blocks
      // loop through each block
      .map((block) => {
        // if it's not a text block with children,
        // return nothing
        if (block._type !== "block" || !block.children) {
          return "";
        }
        // loop through the children spans, and join the
        // text strings
        return block.children.map((child) => child.text).join("");
      })
      // join the paragraphs leaving split by two linebreaks
      .join("\n\n")
  );
}

export function trimLongString(string, length) {
  if (string.length > length) {
    return (string = string.substring(0, length - 1) + "...");
  } else {
    return string;
  }
}

// export const springOne = { type: "spring", stiffness: 250, damping: 25 };
export const springOne = { type: "tween", ease: "easeInOut", duration: 0.4 };
export const springOneDelay = {
  type: "tween",
  ease: "easeInOut",
  duration: 0.3,
  delayChildren: 0.3,
};

// smoothly scroll after delay
export const smoothScrollTo = (el, timerDelay = 600) => {
  const scrollOffset = getCSSCustomProp(
    "--header-height",
    document.documentElement,
    "number"
  );

  const timerId = setTimeout(() => {
    window.scrollTo({
      top: el.offsetTop - scrollOffset,
      behavior: "smooth",
    });
  }, timerDelay);

  return () => clearTimeout(timerId);
};

export const getCSSCustomProp = (
  propKey,
  element = document.documentElement,
  castAs = "string"
) => {
  let response = getComputedStyle(element).getPropertyValue(propKey);

  // Tidy up the string if there's something to work with
  if (response.length) {
    response = response.replace(/'|"/g, "").trim();
  }

  // Convert the response into a whatever type we wanted
  switch (castAs) {
    case "number":
    case "int":
      return parseInt(response, 10);
    case "float":
      return parseFloat(response, 10);
    case "boolean":
    case "bool":
      return response === "true" || response === "1";
    default:
      return response;
  }
};
