import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FLink } from '../../../common/FLink';
import * as styles from './FooterMenu.module.scss';
import { Context } from '../../../../context/Context';
import { localizeField } from '../../../../utils/locale';
import { IconButton } from '../../../common/IconButton';

const FooterMenu = () => {
  const data = useStaticQuery(graphql`
    query FooterMenuQuery {
      sanitySingletonMenus {
        footerMenu {
          linkText {
            _type
            en
            sc
            tc
          }
          slug
        }
        nftLink {
          linkText {
            _type
            en
            sc
            tc
          }
          slug
        }
        signUpLink {
          linkText {
            _type
            en
            sc
            tc
          }
          slug
        }
      }
    }
  `);

  const menuItems = data.sanitySingletonMenus.footerMenu;
  const nftLink = data.sanitySingletonMenus.nftLink;
  const signUpLink = data.sanitySingletonMenus.signUpLink;

  const { language } = useContext(Context);

  return (
    <menu className={styles.footerMenu}>
      <div>&nbsp;</div>
      <div className={styles.footerMenuRight}>
        {/* <div className={styles.buttonLinks}>
          <IconButton icon="arrow-right" to={`/${nftLink.slug}`} bg="purple">
            {localizeField(nftLink.linkText, language.locale)}
          </IconButton>
          <IconButton icon="arrow-right" to={`/${signUpLink.slug}`} bg="grey">
            {localizeField(signUpLink.linkText, language.locale)}
          </IconButton>
        </div> */}
        <div className={styles.utilityMenu}>
          {menuItems.map((item, i) => (
            <div key={`menu-item-${item.slug}-${i}`}>
              <FLink to={`/${item.slug}`}>
                {localizeField(item.linkText, language.locale)}
              </FLink>
            </div>
          ))}
        </div>
      </div>
    </menu>
  );
};

export { FooterMenu };
