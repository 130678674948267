// extracted by mini-css-extract-plugin
export var typeLarge = "Header-module--type-large--OzPzT";
export var typeMedium = "Header-module--type-medium--948jS";
export var typeBody = "Header-module--type-body--WtZ-d";
export var homeIcon = "Header-module--home-icon--c6VM+";
export var header = "Header-module--header--0RNq-";
export var topBar = "Header-module--top-bar--zHOfY";
export var menuBar = "Header-module--menu-bar--Q2YZQ";
export var menuTop = "Header-module--menu-top--3UQgl";
export var hamburger = "Header-module--hamburger--3K58Z";
export var nav = "Header-module--nav--W2OSM";
export var menuItem = "Header-module--menu-item--b08IT";
export var logo = "Header-module--logo--jt1p9";
export var logoLink = "Header-module--logo-link--2C5P7";
export var localeSelector = "Header-module--locale-selector--riZqH";