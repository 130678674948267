import React from 'react';

import { FooterMenu } from './FooterMenu';
import { FooterContent } from './FooterContent';

import * as styles from './Footer.module.scss';

const Footer = ({ className, location }) => {
  // const link = location.pathname.split("/")[1];

  // if (!link) return false;

  return (
    <footer className={styles.footer}>
      <div className={styles.footerInner}>
        <FooterContent />
        <FooterMenu />
      </div>
    </footer>
  );
};

export { Footer };
