// extracted by mini-css-extract-plugin
export var typeLarge = "IconButton-module--type-large--QM0+6";
export var typeMedium = "IconButton-module--type-medium--Yylof";
export var typeBody = "IconButton-module--type-body--gCNOJ";
export var homeIcon = "IconButton-module--home-icon--o9lYf";
export var btn = "IconButton-module--btn--N-LMW";
export var icon = "IconButton-module--icon--RqJOu";
export var transparent = "IconButton-module--transparent--gceJg";
export var purple = "IconButton-module--purple--AGmBi";
export var grey = "IconButton-module--grey--N7FzS";
export var salmon = "IconButton-module--salmon--yLDLd";
export var green = "IconButton-module--green--0kFD0";
export var greenoutline = "IconButton-module--greenoutline--hVYFC";
export var greeninverse = "IconButton-module--greeninverse--ohXmC";
export var border = "IconButton-module--border--gafDl";
export var invertpeach = "IconButton-module--invertpeach--6xSti";
export var dark = "IconButton-module--dark--uBE8e";